import { useRef, useEffect, useState } from 'react';
import Navigation from './Navigation';

const DrawerContent = () => {
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const [showScrollbar, setShowScrollbar] = useState(false);

  const checkForScrollbar = () => {
    if (containerRef.current && contentRef.current) {
      const containerHeight = containerRef.current.offsetHeight;
      const contentHeight = contentRef.current.scrollHeight;
      setShowScrollbar(contentHeight > containerHeight);
    }
  };

  useEffect(() => {
    checkForScrollbar();
    window.addEventListener('resize', checkForScrollbar);

    const observer = new MutationObserver(checkForScrollbar);
    if (contentRef.current) {
      observer.observe(contentRef.current, { childList: true, subtree: true });
    }

    return () => {
      window.removeEventListener('resize', checkForScrollbar);
      observer.disconnect();
    };
  }, []);

  return (
    <div ref={containerRef} style={{ height: '100%' }}>
      <div
        style={{
          maxHeight: '100%',
          overflowY: showScrollbar ? 'auto' : 'hidden'
        }}
      >
        <div ref={contentRef}>
          <Navigation checkForScrollbar={checkForScrollbar} />
        </div>
      </div>
    </div>
  );
};

export default DrawerContent;
