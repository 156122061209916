import { PersonProfile } from 'types/auth';

const getPersonName = (person: PersonProfile) => {
  if (person?.user?.personable_type === 'PhysicalPerson') {
    return getFirstAndLastName(person.name);
  } else {
    return getFirstAndLastName(person.corporate_name);
  }
};

const getFirstAndLastName = (name: string | undefined) => {
  if (!name) {
    return '';
  }

  const words = name.trim().split(/\s+/);

  if (words.length === 1) {
    return words[0];
  } else {
    return `${words[0]} ${words[words.length - 1]}`;
  }
};

const getPersonFunction = (person: PersonProfile) => {
  if (person?.user?.personable_type === 'PhysicalPerson') {
    return person.function;
  } else {
    return person.corporate_name;
  }
};

const getPersonPermission = (permission: String) => {
  switch (permission) {
    case 'support':
      return 'Suporte';
    case 'admin':
      return 'Administrador';
    case 'server_lv1':
      return 'Responsável pelo departamento';
    case 'server_lv2':
      return 'Operacional';
    case 'server_lv3':
      return 'Apenas Leitura';
    default:
      return 'Solicitante Externo';
  }
};

export { getPersonName, getPersonFunction, getPersonPermission, getFirstAndLastName };
