import { Theme } from '@mui/material/styles';
import { useMediaQuery, Link, Grid } from '@mui/material';
import Profile from './Profile';
import DarkMode from './DarkMode';
import Notification from './Notification';
import MobileSection from './MobileSection';
import useConfig from 'hooks/useConfig';
import DrawerHeader from 'layout/MainLayout/Drawer/DrawerHeader';
import { LAYOUT_CONST } from 'types/config';
import useOrganization from 'hooks/useOrganization';
import { Link as RouterLink } from 'react-router-dom';
import { APP_DEFAULT_PATH } from 'config';

const HeaderContent = () => {
  const { menuOrientation } = useConfig();
  const { organization } = useOrganization();
  const { mode } = useConfig();

  const downLG = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'));

  return (
    <>
      {menuOrientation === LAYOUT_CONST.HORIZONTAL_LAYOUT && !downLG && <DrawerHeader open={true} />}

      {organization?.image && mode === 'light' && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Link variant="h4" component={RouterLink} to={APP_DEFAULT_PATH} color="text.primary" underline="none">
            <img src={`${process.env.REACT_APP_URL_BACKEND}${organization?.image}`} alt={organization.name} width="140" height="40" />
          </Link>
        </Grid>
      )}

      {organization?.image && mode === 'dark' && (
        <Grid container direction="row" justifyContent="center" alignItems="center">
          <Link variant="h4" component={RouterLink} to={APP_DEFAULT_PATH} color="text.primary" underline="none">
            {organization.name}
          </Link>
        </Grid>
      )}

      <Notification />
      <DarkMode />
      {!downLG && <Profile />}
      {downLG && <MobileSection />}
    </>
  );
};

export default HeaderContent;
